'use client';

import { notifications } from '@mantine/notifications';
import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

export default function Notify() {
  const searchParams = useSearchParams();
  const message = searchParams.get('message');
  const type = searchParams.get('type');

  useEffect(() => {
    if (!message || !type) return;
    notifications.show({
      title: type === 'error' ? 'Error' : 'Success',
      message,
      color: type === 'error' ? 'red' : 'green',
    });
  }, [searchParams, message, type]);

  return <></>;
}
